// Contact page

.map{
	padding-top: 40px;
	padding-left: 10px;
}
.webforms{
	input,textarea{
		height: auto;
		padding: 6px 15px;
	}
	textarea{
		height:100px;
	}
	label{
		line-height:14px;
		text-align: right;
		float: right;
		@media (max-width: 1309px) {
			float: none;
			text-align: left;
		}
	}
	.row{
		margin-bottom:6px;
	}
	.buttons-set{
		margin:3px 0;
		border:0;
		padding:0;
	}
	h2{
		padding-bottom:10px;
		text-transform:uppercase;
	}
}
.padding-remove{
	padding:0;
}
.partners {
	li {
		margin-bottom:15px;
		@media (max-width: 500px) {
			width:33% !important;
		}
		@media (max-width: 380px) {
			width:50% !important;
		}
	}
}
img {
	max-width:100%;
}
.contact-info{
	padding-top:10px;
	p{
		margin:0 0 5px;
	}
	h2{
		padding-bottom:5px;
	}
}

@media (max-width: @screen-sm) {
	.map{
		padding-left:10px;
		padding-top:30px;
	}
}

@media (max-width: 1309px) {
	.cms-contact #webform_2 .col-xs-10.right {
		width: 100% !important;
	}
}