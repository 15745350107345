.page-404-content {
	width: 514px;
	height: 220px;
	background-color: @c-bg-dark;
	margin: 136px auto;
	padding: 42px 64px;
	text-align: center;

		.title-404 {
		font-size: @font-size-h2;
		font-weight: @badge-font-weight;
		line-height: 120%;
		}

		.info-404 {
		color: @gray;
		font-size: @font-size-text;
		line-height: 160%;
		margin-top: 26px;

			a {
			color: @c-text-link;
			}

		}	
}

/* responsive design for page 404 */

@media only screen and (max-width: 546px) {

	.page-404-content {
		width: 100%;
		height: auto;
		margin: 0px auto;
	}
	
}