.cms-contact {

.page-title {
	margin-bottom: 5px;
}

	fieldset {
		h2 {
		margin: 5px 0px 10px 0px;
		}
		div {
			.input-box:first-child {
				label {
					margin-top: 4px;
				}
			}
			.input-box:nth-child(2) {
				label {
					margin-top: 10px;
				}
			}
			div:nth-child(3) {
				div:first-child {
					label {
						margin-top: 10px;
					}
				}
			}
		}
	}

}

@media (max-width: 1310px) {

	.cms-contact {
		fieldset {
			div {
				.input-box:first-child {
				label {
					margin-top: 0px;
				}
			}
			}
		}
	}
}