// Logo
.logo {
  margin: 0 100px 0 0;
  padding: 20px 0;
  display: inline-block;

  a { display: inline-block; }
  strong { display: none; }

  @media (max-width: @screen-md-max) {
    margin: 0;
  }

}

// Top menu
.top-menu {
  float: right;
  ul {
    border-bottom: 1px dotted #9D9B9A;
    display: inline-block;
    margin: 0;
    li {
      display: inline-block;
      margin-right: 25px;
      &:last-of-type { margin-right: 0; }
      a {
        color: #3B3834;
        display: block;
        padding: 5px 0;
        font-size: 13px;
        font-weight: 300;
        &:hover {
          color: @accent-color;
        }
      }
    }
  }

  @media (max-width: @screen-sm-max) {
    float: none;
    text-align: center;
  }

  @media (max-width: @screen-xs-max) {
    ul {
      li {
        margin-right: 9px;
      }
    }
  }
}

// Search
.form-search {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 35px;
  input {
    width: 387px;
    height: 36px;
    font-weight: 300;
    padding: 0 10px;
  }

  button {
    position: absolute;
    white-space: nowrap;
    right: 6px;
    top: 5px;
    width: 26px;
    height: 26px;
    margin: 0;
    padding: 0;
    background: @accent-color;
    border: none;
    color: #FFF;
    &:hover {
      background: lighten(@accent-color, 5%);
    }
    span {
      line-height: 100%;
    }
  }
  #search_autocomplete {
    background:#fff;
    padding:10px;
    z-index:999;
    box-shadow: 0px 4px 20px -8px #565656;
    .amount {
      width: 45px;
      display: inline-block;
      text-align: center;
      margin-right: 10px;
      border-radius: 7px;
      background: #f1f1f1;
    }
    ul {
      margin:0;
    }
    li {
      padding:5px 0;
      cursor:pointer;
      &:hover {
        color:#F41611;
      }
    }
  }

  @media (max-width: @screen-md-max) {
    margin-right: 10px;

    input {
      width: 290px;
    }
  }

  @media (max-width: @screen-sm-max) {
    width: ~'calc(100% - 123px)';
    margin-right: 0;
    input {
      width: 100%;
    }
  }

}

// Header account + minicart
.header-account,
.header-cart, .header-quote {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #353535;
  > a {
    text-decoration: none;
    line-height: 100%;
    padding: 15px 56px 15px 18px;
    position: relative;
    display: block;
    border: 1px solid transparent;
    display:inline-block;
    span {
      line-height: 1.2;
      font-weight: 300;
      display: block;
      color: #353535;
      &.semibold {
        font-weight: 600;
      }
    }
  }

  @media (max-width: @screen-sm-max) {
    > a {
      padding: 0;
      width: 24px;
      height: 24px;
      span {
        display: none;
      }
    }
  }
}
.header-account {
  @media (max-width: @screen-sm-max) {
    margin-left: 15px;
  }
  &:hover {
    > a {
      background: @c-bg-light;
      border: 1px solid @c-lines;
      color: inherit;
      z-index: 100;
      @media (max-width: @screen-sm-max) {
        background: transparent;
        border: 1px solid transparent;
      }
    }
    > .links {
      display: block;
      @media (max-width: @screen-sm-max) {
        display: none;
      }
    }
  }

  > a {
    &:before {
      content: "";
      background: @sprite no-repeat -10px -71px;
      width: 24px;
      height: 24px;
      display: inline-block;
      position: absolute;
      right: 18px;
      top: 20px;
      @media (max-width: @screen-sm-max) {
        top: 0;
        right: 0;
      }
    }
  }

  .links {
    display: none;
    position: absolute;
    z-index: 99;
    background: @c-bg-light;
    width: 100%;

    li {
      border-left: 1px solid rgb(218, 218, 218);
      border-bottom: 1px solid rgb(218, 218, 218);
      border-right: 1px solid rgb(218, 218, 218);
      padding: 10px 18px;
    }
  }
}
.header-cart {
  @media (max-width: @screen-sm-max) {
    margin-left: 15px;
  }
  &:hover > a {
    background: @c-bg-light;
    border-left: 1px solid @c-lines;
    border-right: 1px solid @c-lines;
    border-top: 1px solid @c-lines;
    color: inherit;
    @media (max-width: @screen-sm-max) {
      background: transparent;
      border: 1px solid transparent;
    }
  }
  &:hover {
    #header-cart {
      display: block;
      @media (max-width: @screen-sm-max) {
        display: none;
      }
    }
  }
  > a.skip-cart {
    &:before {
      content: "";
      background: @sprite no-repeat -44px -71px;
      width: 29px;
      height: 24px;
      display: inline-block;
      position: absolute;
      right: 18px;
      top: 20px;
      @media (max-width: @screen-sm-max) {
        top: 0;
        right: 0;
      }
    }
  }
  .count {
    position: absolute;
    right: 10px;
    font-weight: 400;
    background: none repeat scroll 0% 0% rgb(53, 53, 53);
    color: rgb(255, 255, 255);
    display: block;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    top: 13px;
    @media (max-width: @screen-sm-max) {
      top: -5px;
    }
  }
  #header-cart {
    display: none;
    position: absolute;
    z-index: 99;
    width: 560px;
    right: 0;
    background: @c-bg-light;;
    border-radius: 0;
    border: 1px solid @c-lines;

    .minicart-wrapper {
      padding: 20px;

      p.empty {
        margin-bottom: 0;
      }
      .row_wide {
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 10px;
        padding: 20px 20px 0px 20px;
      }

      .block-content {
        padding: 0px 20px;;
      }

      .minicart-actions {
        padding: 0px 20px;
      }
      .product-details {
        margin-left: 0px;
      }
      .item-mini-cart {
        padding: 15px 20px;
        border-bottom: 1px solid @c-lines;

        &:first-of-type {
          border-top: 1px solid @c-lines;
        }
        .imgCart {
          float: left;
          margin-right: 17px;
        }
        .product-name {
          font-weight: 400;
          text-transform: none;
          line-height: 130%;
        }
        .remove_item {
          a {
            background: @sprite no-repeat -138px -65px;
            width: 17px;
            height: 18px;
            display: block;
            position: absolute;
            right: 20px;

            &:hover {
              background: @sprite no-repeat -165px -65px;
            }
          }
        }
      }
    }
  }
}

.header-quote {
  a.skip-quote {
    &:before {
      content: "";
      background: url(../../dist/img/sprite.png) no-repeat -8px -379px;
      width: 23px;
      height: 26px;
      display: inline-block;
      position: absolute;
      right: 18px;
      top: 20px;
      @media (max-width: @screen-sm-max) {
        top: 0;
        right: 0;
      }
    }
  }
  @media (max-width: @screen-sm-max) {
    margin-left:10px;
  }
}

// LAUOUT
header {
  .container;

  @media (max-width: @screen-sm-max) {
    .pull-left {
      float: none !important;
      text-align: center;
    }
    .pull-right {
      float: none !important;
      margin-bottom: 15px;
    }
  }
}

/* modularity start */
.minicart-subtotal {
  > span {
    display: inline-block;
  }
}