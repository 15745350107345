.amshopby-filters-top {
  margin-bottom: 25px;
  .block-content {
    position: relative;
  }

  .currently {
    display: inline-block;
    ol {
      margin-bottom: 0;

      li {
        display: inline-block;
        margin: 0 20px 5px 0;

        a.filter {
          background: #F3F2F6;
          padding: 3px 10px;
          display: inline-block;
          &:after {
            content: '\f00d';
            font-family: FontAwesome;
          }
        }
      }
    }
  }
  .actions {
    display: inline-block;
    margin-top: 5px;
    a {
      padding: 3px 10px;
      display: inline-block;
      background: #F91C1C;
      color: #fff;
      &:after {
        content: '\f00d';
        font-family: FontAwesome;
        margin-left: 5px;
      }
    }
  }

  .block-content ol {
    max-width: 100%;
  }
}

// Icons
div.block-layered-nav dd a.amshopby-attr, div.block-layered-nav dd a.amshopby-attr:hover {
  position: relative;
  padding-left: 23px;
  background: none;
  &:before {
    content: ' ';
    background: @sprite -116px -137px;
    width: 16px;
    height: 16px;
    position: absolute;
    display: block;
    left: 0;
    top: 2px;
  }
}
div.block-layered-nav dd a.amshopby-attr-selected {
  position: relative;
  font-weight: normal;
  padding-left: 23px;
  background: none;
  &:before {
    content: ' ';
    background: @sprite -116px -114px;
    width: 16px;
    height: 16px;
    position: absolute;
    display: block;
    left: 0;
    top: 2px;
  }
}

.filters-title {
  font-size: 14px;
  text-transform: uppercase;
  color: #353535;
  font-weight: 700;
  margin-bottom: 10px;
}