/* @ Autor: Tiberiur
 * @ Title: Gomage One Page Checkout
 */

body .simple_overlay button.button:hover span span,
body .glc button.button:hover span span,
body .simple_overlay button.button span span,
body  .glc button.button span span {
  background: transparent !important;
}
.glc .data-table .odd, .glc .data-table .even {
  background: transparent !important;
}
.glc-title {
  background: transparent !important;
}
body .glc #checkout-review-table,
body .glc #checkout-review-table td,
body .glc #checkout-review-table th {
  border-color: #ddd !important;
}

.gomage-left {
  background: #FDFCF8;
  border: 1px solid #DADADA;
  padding: 0 35px 25px 35px !important;
  .glc-title {
    font-family: @font-family-sans-serif;
    font-size: 18px;
    color: @c-text-link-prime;
    text-transform: uppercase;
    border-bottom: 1px solid @c-lines;
    padding-bottom: 10px;
    margin: 25px 0 10px;
    background: transparent;
  }
  .glc-title strong {
    font-weight: @headings-font-weight;
  }
  .glc-title .glc-number {
    display: none;
  }
  .glc-col {
    background: transparent;
    border: transparent;
    color: inherit;
  }
  .form-list li {
    margin: 0;
  }
  .form-list li.control {
    margin-bottom: 20px;
  }
  .field {
    width: 100%;
    padding: 0;

  }
  .input-box {
    width: ~"calc(100% - 89px)";
    display: inline-block;
    margin-bottom: 10px;
    position: relative;
  }
  .control .control-register {
    margin-bottom: 13px;
  }
  .input-box input[type=text],
  .input-box input[type=password],
  .input-box select {
    width: 100%;
    padding: 0 10px;
    height: 30px;
    border: 1px solid #DADADA;
  }
  .input-box input[type="text"]:focus,
  .input-box input[type="password"]:focus,
  .input-box select:focus {
    border: 1px solid @c-input-focus;
  }
  textarea {
    border: 1px solid @c-input-border;
    background: #FFF;
    padding: 0 10px;
  }

  .customer-comment textarea {
    width: 100%;
    height: 50px;
  }
  .input-box select {
    box-shadow: none;
  }
  input[type=radio],
  input[type=checkbox] {
    display: inline-block;
    vertical-align: top;
    margin: 4px 5px 0;
    float: none !important;
  }
  label {
    width: 75px;
    margin: 8px 10px 0 0;
    float: left !important;
    padding: 0;
    text-transform: none;
    text-align: right;
    font-weight: 400;
    line-height: 100%;
    &[for="billing_confirm_password"],
    &[for="billing_taxvat"]{
      margin: 1px 10px 0 0;
    }
  }
  .customer-name-prefix,
  #billing_taxvat_verified {
    display: none;
  }
  .control label,
  #gcheckout-onepage-methods label,
  .customer-comment label,
  label[for="billing:pfpj_for_billing"],
  label[for="shipping:pfpj_for_shipping"] {
    width: auto;
    font-weight: 400;
    margin: 0 10px 0 0;
    float: none !important;
    display: inline-block !important;
  }
  input[id="shipping:pfpj_for_shipping"] {
    position: absolute;
    top: 3px;
  }
  label[for="shipping:pfpj_for_shipping"] {
    margin: 0;
    text-align: left;
    padding-left: 25px;
  }
  #gcheckout-shipping-method-available .sp-methods > dt {
    display: none;
  }
  label em {
    display: none;
  }

  // Textarea: indicatii livrare
  .customer-comment {
    margin-bottom: 10px;
  }

  // Label control
  .control {
    label {
      text-align: left;
      line-height: 1.2;
    }

    &.control-subscribe {
      position: relative;
      padding-left: 28px;
      input {
        position: absolute;
        left: 0;
      }
    }
  }
}
.gomage-right {
  background: #FDFCF8;
  border: 1px solid #DADADA;
  padding: 40px 30px !important;
  margin-bottom: 30px;



  .glc-title {
    margin: 0 0 30px 0;
  }
  .glc-title {
    font-family: @font-family-sans-serif;
    font-size: 18px;
    color: @c-text-link-prime;
    text-transform: uppercase;
    line-height: 120%;
    border-bottom: 1px solid @c-lines;
    padding: 0px 0px 5px 25px;
    position: relative;
  }
  .glc-title:before {
    content: '\f07a';
    font-family: FontAwesome;
    position: absolute;
    left: 0;
  }
  .glc-title strong {
    font-weight: 700;
  }
  .glc-title .glc-number {
    display: none;
  }
  #checkout-review-table {
    border: none;
  }
  #checkout-review-table thead {

  }
  #checkout-review-table thead th {
    font-family: @font-family-sans-serif;
    font-size: 15px;
    color: @c-text-link-prime;
    text-transform: uppercase;
    border: none;
    font-weight: 700;
    padding: 12px 0 5px;
  }
  #checkout-review-table tbody tr {
    border-top: 1px solid rgb(211, 211, 211);
    border-bottom: 1px solid rgb(211, 211, 211);
  }
  #checkout-review-table tbody td {
    vertical-align: middle;
    padding: 10px;
  }
  #checkout-review-table tbody td.lc-thumbnails {
    border-right: none;
    padding: 10px 10px 10px 0;
  }
  #checkout-review-table tbody .product-name,
  #checkout-review-table tbody span {
    font-family: @font-family-sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: @c-text-link-prime;
    text-transform: none;
  }

  #checkout-review-table tbody .product-sku {
    font-size: 11px;
    color: @c-text-link;
    text-transform: uppercase;
  }
  #checkout-review-table tbody .product-name .manufacturer {
    text-transform: uppercase;
    margin: 0;
  }
  #checkout-review-table tbody td.light-chechout-remove-td > span {
    margin: 0 5px;
  }
  #checkout-review-table tbody .glc-qtybtn {
    background: #111;
    border-radius: 3px;
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
    line-height: 16px;
    text-align: center;
  }
  #checkout-review-table tbody .glc-qtybtn.glc-plus:before {
    content: "+";
    color: #FFF;
  }
  #checkout-review-table tbody .glc-qtybtn.glc-minus:before {
    content: "-";
    color: #FFF;
  }

  #checkout-review-table tfoot {
    border: 1px solid #D3D3D3;
  }
  #checkout-review-table tfoot tr:first-of-type {
    margin-top: 20px;
  }
  #checkout-review-table tfoot tr td {
    padding: 5px;
    border-right: none;
    font-family: @font-family-sans-serif;
    font-size: 14px;
    color: @c-text-link-prime;
  }
  #checkout-review-table tfoot tr.last td {
    font-size: 18px;
    color: #E6231B;
  }
  .lightcheckout-discount-form {
    margin-top: 10px;
  }
  .lightcheckout-discount-form label {
    font-weight: 600;
    color: @c-text-link;
    cursor: pointer;
    text-decoration: underline;
  }
  .discount-bottom {
    float: right;
    text-align: right;
  }
  .lightcheckout-discount-form .input-box {
    display: none;
    #coupon_code {
      float: left;
      width: 250px;
      height: 34px;
      margin-right: 10px;
    }
  }
  .lightcheckout-discount-form .input-box.open {
    display: block;
  }
}
#checkout-review-submit {
  margin: 20px 0 0;
}


@media only screen and (max-width: 767px) {
  #checkout-review-table {
    margin: 0 auto 20px;
    border-collapse: collapse;
    text-rendering: optimizeLegibility;
    thead {
      display: none;
    }
    .item-options {
      display: table;
    }
    td {
      display: block;
      border: 0;
      padding: 0 !important;
      text-align: left !important;

      &:first-child, &:last-child {
        display: table-cell;
        width: 1px;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }
      &:first-child {
        padding-right: 10px !important;
      }
      &:last-child {
        padding-left: 10px !important;
      }
      &:last-child {
        border-left: 1px solid @table-border-color;
      }
      & .qty-text {
        float: left;
        margin-left: 8px;
      }
      &:nth-last-child(2) {
        clear: both;
      }

      &.lc-thumbnails {
        vertical-align: top !important;
      }
      &.table-product-name {
        padding-top: 10px !important;
      }
      &.a-center {
        text-align: left !important;
      }

      &.gomage-price {
        .cart-price .price:before {
          content: 'Prescio: ';
          font-weight: 600;
        }
      }
      &.gomage-total {
        padding-bottom: 10px !important;
        .cart-price .price:before {
          content: 'Totales: ';
          font-weight: 600;
        }
      }

      tr {
        border-bottom: 1px solid @table-border-color;

        &:first-child {
          border-top: 1px solid @table-border-color;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }

  .discount-bottom {
    width: 100%;
    .lightcheckout-discount-form .input-box #coupon_code {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
    .lightcheckout-discount-form .input-box button {
      padding: 9px 5px !important;
    }
  }
}

#info-pret {
  float: right;
  width: 100%;
}
#info-pret tr:last-of-type td {
  padding-bottom: 15px;
}
#info-pret tr td {
  padding: 2px 10px;
}
#info-pret tr td h2 {
  margin: 0;
}
#info-pret tr:last-of-type td {
  font-size: 18px;
  color: @c-text-link-prime;
  font-weight: 400;
}
#info-pret tr td.a-right {
  width: 100px;
}
#info-pret tr td strong {
  font-weight: 400;
}

.glc-login-and-paypal {
  margin: 15px 0;
  font-size: 16px;
  color: @gray-dark;
}
.glc-login-and-paypal .login-button {
  font-size: 16px;
  color: @c-text-link;
  text-decoration: underline;
}

/* Erori */
.validation-advice-container {
  position: relative;
  background: @c-btn-primary;
  border-radius: 4px;
  padding: 0 10px;
  color: #FFF;

  &:after {
    bottom: 100%;
    left: 10%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: @c-btn-primary;
    border-width: 6px;

  }
}
@media screen and (max-width: 400px) {
  .gomage-left {
    padding: 0 15px 10px 15px !important;
    .control {
      label {
        width: ~"calc(100% - 38px)";
      }
    }
    label {
      margin: 0 0 5px 0;
      text-align: left;
    }
    .input-box {
      width: 100%;
      margin-bottom: 5px;
    }
  }

  .gomage-right {
    padding: 20px 15px !important;
  }
}
/* Loading message */
.loadinfo {
  background: #FFF;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;

  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}
#gomage-checkout-main-overlay {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: none repeat scroll 0% 0% rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 9;
}
#login-form.simple_overlay,
#terms-block.simple_overlay {
  background: #FFF;
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  max-width: 447px;
  width: 90%;
  z-index: 99;

  margin: 0 !important;

  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);

  .close {
    background: @sprite no-repeat scroll -10px -266px transparent;
    width: 30px;
    height: 32px;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 1;

    &:hover {
      background: @sprite no-repeat scroll -50px -266px transparent;
    }
  }
  .details {
    padding: 10px 20px;
    height: 300px;
    overflow: auto;
    h2 {
      margin-left: 10px;
      text-transform: uppercase;
    }
    hr {
      width: 95%;
      margin-top: 0px;
    }
    .form-list {
      margin: 25px 20px 12px 12px;
      li {
        margin: 10px 0px !important;
      label {
        width: 52px;
        float: left;
        line-height: 34px;
        margin: 0px;
        padding: 0px;
      }
      input {
        width: ~'calc(100% - 52px)';
      }
    }
    }
    .actions {
      width: 100%;
      height: 34px;
      margin-bottom: 25px;
      button {
        margin-left: 65px;
        background-color: @c-btn-primary;
        color: #fff;
        font-weight: 700;
        border: none;
        float: left;
        padding: 7px 12px;
      }
      .forgot-link {
        float: left;
        margin-left: 20px;
        line-height: 34px;
      }
    }
  }
}

#gcheckout-forgot-wrapper {
  height: 210px !important;
  .actions button:last-of-type {
    margin-left: 5px !important;
  }
}