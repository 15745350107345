.homepage_banners_sus {
	margin-bottom:10px;
	margin-top: 20px;
	.homepage_banners {
		height:1px;
		overflow:hidden;
		padding:0;
		margin:0;
	}
	img {
		width:100%;
		height:auto;
	}
}
.home-page-free-shipping {
	min-height: 55px;
	color: #111111;
	background-color: #EEEEEE;
	border: 1px solid #CCCCCC;
	padding: 10px 23px;
	margin-top: 21px;
	font-size: 22px;
	text-transform: uppercase;
	margin-bottom: 60px;
	span {
		color: @accent-color;
		font-weight: 700;
	}
	p {
		margin-bottom:0;
	}
}

.voucher {
	margin-bottom:20px;
	.voucher-text {
		padding:15px 25px;
		border-radius:3px;
		color:#fff;
		font-size:22px;
		line-height: 120%;
		font-family:@font-family-sans-serif;

		background: rgba(43,115,197,1);
		background: -moz-linear-gradient(top, rgba(43,115,197,1) 0%, rgba(43,115,197,1) 24%, rgba(22,87,162,1) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(43,115,197,1)), color-stop(24%, rgba(43,115,197,1)), color-stop(100%, rgba(22,87,162,1)));
		background: -webkit-linear-gradient(top, rgba(43,115,197,1) 0%, rgba(43,115,197,1) 24%, rgba(22,87,162,1) 100%);
		background: -o-linear-gradient(top, rgba(43,115,197,1) 0%, rgba(43,115,197,1) 24%, rgba(22,87,162,1) 100%);
		background: -ms-linear-gradient(top, rgba(43,115,197,1) 0%, rgba(43,115,197,1) 24%, rgba(22,87,162,1) 100%);
		background: linear-gradient(to bottom, rgba(43,115,197,1) 0%, rgba(43,115,197,1) 24%, rgba(22,87,162,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2b73c5', endColorstr='#1657a2', GradientType=0 );

		span {
			float:right;
			a {
				color:#fff;
				font-family:@font-family-sans-serif;
				text-decoration:underline;
				font-size: 13px;
				text-transform:uppercase;
				font-weight: bold;
			}
		}
	}
}
.middle-banners {
	img {
		width:100%;
		height:auto;
	}
}

.ftr {
	background:@c-bg-dark;
	padding-top:28px;
	padding-bottom: 5px;
	.footer-separator {
		width:100%;
		height:1px;
		background:@gray-lighter;
		margin:10px 0;
	}
	.links {
		.block-title {
			font-family: @font-family-sans-serif;
			font-size: @font-size-small;
			line-height: 160%;
			text-transform:uppercase;
			color:@gray-dark;
			padding-bottom:5px;
			display:table;
			strong {
				span {
					display:table-cell;
					vertical-align:bottom;
					height:50px;
					color: @c-text-link-prime;
				}
			}
		}
		ul {
			li {
				display:block;
				a {
					font-family: @font-family-sans-serif;
					font-size: @font-size-small;
					font-weight: 400;
					line-height: 160%;
					color:@c-text-link;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	.block {
		background:none;
		border:0 none;
		border-radius:0;
		.block-title {
			padding-bottom:2px;
			display:table;
			strong {
				font-size: @font-size-small;
				span {
					font-family: @font-family-sans-serif;
					font-size: @font-size-small;
					line-height: 160%;
					text-transform:uppercase;
					color:@c-text-link-prime;
					display:table-cell;
					vertical-align:bottom;
					height:50px;
				}
			}
		}
		.block-content {
			ol#recently-viewed-items {
				li.item {
					padding: @grid-gutter-width / 2;
					padding-top:0;
					.product-image {
						background:#fff;
						border:1px solid @c-lines;
						margin-bottom:7px;
						img {
							margin:0 auto;
						}
					}
					.product-name {
						font-weight:400;
						a {
							font-family: @font-family-sans-serif;
							font-size: 12px;
							line-height: 120%;
							color: #555;
							display:block;
						}
					}
				}
			}

			.input-box {
				margin-bottom:10px;
				max-width:400px;
				.input-text {
					border:1px solid @c-lines;
					height:42px;
					color:@gray-dark;
					font-family: @font-family-sans-serif;
					width:100%;
					&:focus {
						border:1px solid @gray-dark;
					}
				}
				#newsletter {
					color: #222;
					font-weight: 600;
					border: 1px solid @border-newsletter;
				}
			}
			.actions {
				padding-left:0;
				text-align:left;
				button {
					background:@c-text-link-prime;
					border-color:@c-text-link-prime;
					color: #e2e2e2;
					border-radius:0;
					padding:10px 20px;
					span {
						span {
							font-family: @font-family-sans-serif;
							font-size: @font-size-small;
							line-height: 120%;
							text-transform:uppercase;
						}
					}
					&:hover {
						background: #F64E4B;
						color: #FFF;
						border-color: #F64E4B;
					}
				}
			}
		}
	}
	.block-subscribe {
		.block-title {
			margin-bottom: 2px;
		}
	}
	.footer-icons-footer {
		display:inline-block;
		float:right;
		margin-left:20px;
		padding:10px 0;
		ul {
			margin:0;
			padding:0;
			li {
				display:inline-block;
				padding:0 5px ;
				&:first-child {
					padding-left:0;
				}
				a {
					color:@c-text-link-prime;
					font-size:14px;
					line-height: 120%;
					i {
						&.fa-facebook {
							&:hover {
								color:#325C94;
							}
						}
						&.fa-twitter {
							&:hover {
								color:#00ABDC;
							}
						}
						&.fa-youtube-play {
							&:hover {
								color:#F20026;
							}
						}
						&.fa-pinterest {
							&:hover {
								color:#D9031F;
							}
						}
					}
				}

			}
		}
	}
	.footer-adresa-footer {
		padding:10px 0;
		display:inline-block;
		font-size:@font-size-small;
		line-height: 160%;
		margin-right:30px;
		strong {
			color: @c-text-link-prime;
		}
	}
	.footer-payment-icons {
		padding:10px 0;
		float:right;
		display:inline-block;
		vertical-align:middle;
		font-size:@font-size-small;
		line-height: 160%;

		@media screen and (max-width:1300px) {
			width:100%;
			text-align:left;
		}

		@media screen and (max-width:400px) {
			img {
				width:100%;
				height:auto;
			}
		}
	}

}