.quotations-quote-index aside[role="complementary"] {
  textarea {
    border: 1px solid #DADADA;
    width: 100%;
    resize: vertical;
  }
}
@media (max-width: 992px) {
  .quotations-quote-index aside[role="complementary"] {
    position: static;
    transform: none;
    width: 100%;
  }
}

// Product view
.btn-quotation {
  margin-bottom: 10px;
}

// Sidebar
#quotation_history_block {
  .block-title {
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #DADADA;
    strong {
      font-weight: 300;
    }
  }
}

// Form
#quote-form {
  textarea {
    border: 1px solid #DADADA;
    width: 100%;
    resize: vertical;
  }
}

#qquote-tbl-billing, #qquote-tbl-shipping {
  label {
    display:block;
  }
  select {
    width:250px;
    border:1px solid #DADADA; 
  }
  @media (max-width: 580px) {
    td {
      display:block;
      width:100% !important;
      padding-left:0;
    }
  }
}

// Table
@media (max-width: 660px) {
  #quote-form .data-table {
    margin-top: 20px;
    width: 100%;
    display: block;
    tbody, thead, tfoot, tr, th, td {
      display: block;
    }
    thead {
      display: none;
    }
    td {
      border: none;
      padding: 5px 0 5px 85px;
    }
    tr {
      border-bottom: 1px solid #ddd;
      padding: 10px 0;
    }
    .column-image {
      float: left;
      padding-left: 0;
    }
  }
}