/* Slider */

// Next/Prev button
.slick-slider {
  .button {
    color: @c-arrow;
    border-radius:50% ;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    border:2px solid @c-arrow;
    line-height: 100%;
    font-size: 25px;
    width: 45px;
    height: 45px;
    z-index: 1;
    &:hover{
      color: @c-arrow-h;
      border-color: @c-arrow-h;
    }

    i {
      position: absolute;
      width:100%;
      text-align: center;
    }

    &.slick-prev {
      left:-10px;
      i { top: 9px; left: -2px; }

      @media (max-width: @screen-xs-max) {
        left: 5px;
      }
    }
    &.slick-next {
      right:-10px;
      i { top: 9px; left: 2px; }

      @media (max-width: @screen-xs-max) {
        right: 5px;
      }
    }

  }
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-dots {
    position:absolute;
    right:20px;
    bottom:10px;
    z-index:9;
    
    li {
        float:left;
        padding-left:5px;
        list-style:none;
        button {
            text-indent:-9999px;
            background:@gray-dark;
            height:15px;
            width:16px;
            border-radius:16px;
            border:1px solid #fff;
        }
        &.slick-active {
            button {
                background:@c-btn-primary;
            }
        }
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}