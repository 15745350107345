// **************** Blog

.menu-categories{
  background:@c-bg-light;
  padding:43px 30px;

  h4{
    border-bottom:1px solid #CBCBCB;
    padding-bottom:12px;
    margin:0;
  }

  li{
    border-bottom:1px solid #E5E5E5;
    padding:9px 11px;
    a{
      text-transform:uppercase;
      color:#000;
    }
  }
}

.postinfo{
  border-bottom:1px solid #E5E5E5;
  width:100%;
  float: left;
  padding:7px 0;
  text-transform:uppercase;
}
.postTitle{
  clear:both;
  display:inline-block;
  width:100%;
  h4{
    &:extend(#postComment h4);
  }
  .legend{
    border-bottom:1px solid #DDDDDD;
  }
}
.aw-blog-read-more{
  float:left;
  padding-top:12px;
}
#postComment{
  width:100%;
  .input-box{
    padding:6px 0;
  }
  p.required{
    text-align: left;
    color:#B2B2B2;
  }
  .button{
    background:@gray-dark;
    color:@c-btn-default-h;
    margin-top:11px;
  }
  h4{
    text-transform:uppercase;
    font-size:16px;
    padding-bottom:14px;
    width:100%;
    margin-bottom:0;
  }
  .form-list{
    padding-top:20px;
    padding-bottom:38px;
  }
}
.postWrapper{
  padding-bottom:20px;
  float: left;
  width:100%;
  img{
    width:100%;
  }
  .postContent{
    float: left;

    .aw-blog-read-more{
      &:after{
        content:"\f105";
        font: normal normal normal 14px/1 FontAwesome;
        margin-left:4px;
        font-size:13px;
      }
    }
  }
}
.blog-index-list,.blog-post-view{
  div[role="main"]{
    .make-md-column(8);
  }
  aside[role="complementary"]{
    .make-md-column(3);
    float: right !important;
    padding:0 !important;
  }
}
.commentWrapper{
  .commentInner{
    border-bottom:1px solid #E5E5E5;
    padding:27px 0 7px;
    &:last-child{
      border-bottom:0;
    } 
    h4{
      text-transform:uppercase;
      font-weight:normal;
    }
    .postauthor{
      padding:18px 0;
    }
  }
}
.social-share{
  width:100%;
  border-top:1px solid #E5E5E5;
  float: left;
  padding-top:18px;
  padding-bottom:45px;
  .share-links{
    float: right;
    li{
      float: left;
      margin:0 11px;
    }
  }
  h5{
    float: right;
    font-weight:normal;
    text-transform:uppercase;
    margin:2px;
    margin-right:28px;
  }
}
.blog-view,.blog-list{
  margin-left:-10px;
  .pages{
    padding-bottom:58px;
  }
}
.blog-view{
  .postContent{
    padding-top:20px;
  }
}
.blog-menu{
  margin-right:-10px;
}

.blog-post-view, .blog-index-list,.blog-cat-view{
  .sort-by{
    display:none;
  }
}


@media (max-width: @screen-sm) {
  .blog-view, .blog-list{
    margin-left:0;
  }
  .blog-menu{
    width:100%;
    margin-right:0;
    margin-bottom:40px;
  }
}