.checkout-onepage-success {
	.col2-set {
		.col-2 {
			img {
				width:100%;
				position: relative;
			}
		}
		.col-1 {
			h2 {
				font-size: 25px;
				border-bottom: 4px solid @c-text-link;
				padding: 0px 0px 10px 0px;
			}
			p {
				a {
					color: @c-text-link;
				}
			}
		}
	}
}