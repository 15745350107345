@menu-bg: #111111;
@menu-bg-h: #F8F8F8;
@menu-line-separator: #343434;
@menu-dropdown-bg: #F8F8F8;

.cms-index-index .primary-nav {
  margin-bottom: 0;
}
.primary-nav {
  z-index: 9;
  position: relative;
  background: @menu-bg;
  margin-bottom: 28px;
  @media (max-width: @screen-sm-max) {
    background: @menu-bg;
  }

  #nav {
    @media (max-width: @screen-xs-max) {
      margin: 0 -10px;
    }
    // 1st level
    > .nav-item {
      font-weight: 600;
      font-size: 18px;
      color: #fff;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      border-left: 1px solid @menu-line-separator;
      &.first {
        &:hover { color: #fff; }
        a {
          > span {
            background: @accent-color;
            padding: 7px 15px;
            line-height: 100%;
            @media (max-width: @screen-sm-max) {
              display: block;
              padding: 15px 10px;
            }
          }
          @media (max-width: @screen-sm-max) {
            padding: 0;
          }
        }
      }
      &.last {
        border-right: 1px solid @menu-line-separator;
      }
      @media (max-width: @screen-sm-max) {
        background: @menu-bg;
        border: none !important;
        border-bottom: 1px solid rgba(255, 255, 255, .3) !important;
      }

      &:hover {
        background: @menu-bg-h;
        color: @menu-bg;
        border-top: 1px solid @menu-bg;
        border-left: 1px solid @menu-bg;
        border-bottom: 1px solid @menu-bg;
        @media (max-width: @screen-sm-max) {
          background: @menu-bg;;
          color: #FFF;
        }
      }


      > a {
        color: inherit;
        padding: 0 15px;
        @media (max-width: @screen-md-max) {
          font-size: 16px;
        }
        @media (max-width: @screen-sm-max) {
          padding: 15px 10px;
        }

        .caret {
          display: none;
        }

      }
    }

    // dropdown
    .nav-panel--dropdown {
      border: 1px solid @menu-bg;
      background: @menu-dropdown-bg;
      border-top: none;
      font-size: 14px;
      font-weight: 400;
      color: #343434;
      width: 270px;
      text-transform: none;
      @media (max-width: @screen-sm-max) {
        background: #f8f8f8 !important;
        border: none !important;
      }

      .nav-item {
        a {
          padding: 7px 0;
          border-bottom: 1px solid #DDDDDD;
          &:hover {
            color: #F31511;
          }
          @media (max-width: @screen-sm-max) {
            padding: 15px 10px;
          }
        }
      }

      // mega menu
      .nav-submenu--mega {
        > .nav-item {
          > a {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 600;
            @media (max-width: @screen-sm-max) {
              font-size: 14px;
              text-transform: uppercase;
            }
          }
          .nav-submenu {
            .nav-item {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
}

// Suprascrieri style meniu
.nav-regular .nav-submenu--mega > li > ul > li {
  background: none;
}


// MOBILE
.acco li.level1 > a {
  text-transform: none;
}

// Mobile trigger
.mobnav-trigger-wrapper {
  .clearfix();
}
.mobnav-phone {
  float: left;
  text-align: right;
  line-height: 48px;
  width: 55%;
  font-weight: 700;
  font-size: 17px;
  color: #fff;
  white-space: nowrap;
  &:hover {
    color: #F41611;
  }
  &:before {
    content: '\f098';
    font-family: FontAwesome;
    display: inline-block;
    margin-right: 8px;
    font-weight: 400;
    color: #F41611;
  }
}
.mobnav-trigger {
  background: transparent;
  position: relative;
  width: 45%;
  float: left;
  outline: 0 !important;
  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.3);
    width: 1px;
    height: 100%;
  }
  span {
    font-weight: 600;
    font-size: 17px;
    text-transform: uppercase;
    color: #fff;
  }
  .trigger-icon {
    width: 28px;
    .line {
      margin-bottom: 6px;
      height: 4px;
    }
  }
}

// Opener
.acco {
  .opener {
    position:absolute;
    right:0;
    top:0;
    display:block;
    cursor:pointer;
    text-align:center;

    width:49px;
    height:49px;
    line-height:49px;
    &:before {
      content: '\f107';
      font-family: FontAwesome;
      display: block;
    }
    &:hover {

    }
  }

  li.item-active > .opener {
    &:before {
      content: '\f106';
    }
  }
}

/* Level 1
-------------------------------------------------------------- */
.acco li.level0 > a {
  font-size: 18px;
  text-transform: uppercase;
  @media (max-width: @screen-sm-max) {
    text-transform: none;
  }
}

/* Level 2
-------------------------------------------------------------- */
.acco li.level1 > a {
  padding-left: 30px;
  font-size: 16px;
  text-transform: uppercase;
  @media (max-width: @screen-sm-max) {
    text-transform: none;
  }
}

/* Level 3
-------------------------------------------------------------- */
.acco li.level2 > a {
  padding-left: 50px;
  font-size: 16px;
  text-transform: none;
}

/* Level 4
-------------------------------------------------------------- */
.acco li.level3 > a
{
  padding-left: 70px;
  font-size: 14px;
  text-transform: none;
}

/* Level 5+
-------------------------------------------------------------- */
.acco li.level3 .nav-item > a /* Level 5+ */
{
  padding-left: 90px;
  font-size: 12px;
  text-transform: none;
}

.cat-thumb {
  min-height: 85px;
  margin-bottom: 5px;
  @media (max-width: @screen-sm-max) {
    display: none;
  }
}
.nav-submenu--mega .nav-item--only-subcategories {
  > a {
    border-bottom: none !important;
    &:hover {
      span {
        color: #F31511;
      }
    }
    span {
      color: #202020;
    }
  }

  .nav-submenu {
    font-size: 12px;
    a {
      color: #343434;
      @media (max-width: @screen-sm-max) {
        font-size: 13px;
      }
    }
  }
}

//TODO: de facut ceva cu asta de mai jos
/* Itemgrid: different numbers of columns
-------------------------------------------------------------- */
/* Clear first item in each row (row: group of n items) */

/* Item grid: 1 column */
.dd-itemgrid-1col > .nav-item { width:98%; }

/* Item grid: 2 columns */
.dd-itemgrid-2col > .nav-item { width:48%; }
.dd-itemgrid-2col > .nav-item:nth-child(2n+1) { clear:left; }

/* Item grid: 3 columns */
.dd-itemgrid-3col > .nav-item { width:31.3333%; }
.dd-itemgrid-3col > .nav-item:nth-child(3n+1) { clear:left; }

/* Item grid: 4 columns */
.dd-itemgrid-4col > .nav-item { width:23%; }
.dd-itemgrid-4col > .nav-item:nth-child(4n+1) { clear:left; }

/* Item grid: 5 columns */
.dd-itemgrid-5col > .nav-item { width:18%; }
.dd-itemgrid-5col > .nav-item:nth-child(5n+1) { clear:left; }

/* Item grid: 6 columns */
.dd-itemgrid-6col > .nav-item { width:16.6666%; }
.dd-itemgrid-6col > .nav-item:nth-child(6n+1) { clear:left; }

/* Item grid: 7 columns */
.dd-itemgrid-7col > .nav-item { width:12.2857%; }
.dd-itemgrid-7col > .nav-item:nth-child(7n+1) { clear:left; }

/* Item grid: 8 columns */
.dd-itemgrid-8col > .nav-item { width:10.5%; }
.dd-itemgrid-8col > .nav-item:nth-child(8n+1) { clear:left; }