&.cms-page-view {
	article {
		h1 {
			font-family: $fos;
			font-size: 31px;
			color: $color_5;
			line-height: 120%;
			font-weight: 800;
			text-transform: none;
			margin:0px
		}
		h2 {
			font-family: $fos;
			font-size: 24px;
			color: #EBDFC5;
			line-height: 120%;
			font-weight: 600;
			text-transform: none;
            text-align: center;
		}
		h3 {
			font-family: $fos;
			font-size: 18px;
			color:#434752;
			line-height: 120%;
			border-bottom: none;
			text-transform: none;
			font-weight:800;
		}
		p {
			font-family: Open sans, sans-serif;
			color: $ctext;
			font-family: $fos;
			font-size:15px;
			font-style: normal;
			line-height: 164%;
		}
		ul {
			list-style-position: outside;
			list-style-type: square;

			li {
				margin-left: 1em;
				font-family: $fos;
				font-size: 18px;
				color:#3A3833;
				font-style: normal;
				line-height: 140%;
			}
		}
		img {
			width: 100%;
		}
		.hr-title {
			width:100%;
			height: 5px;
			display:inline-block;
		}
		@media screen and (max-width: 991px) {
			.row {
				> div {
					margin-bottom: 20px;
				}
			}
		}
	}
}
&.cms-download {

		.page-title {
			margin-bottom:46px;
		}
.std {
	div {
		h3 {
			margin: 47px 0px 10px 0px;
		}
		span {
			display: block;
			em {
				float: left;
				margin: 10px 10px 0px 0px;
			}
			p {
				color: #3B3834;
				font-family: "Open Sans",sans-serif;
				font-size: 15px;
				font-style: normal;
				line-height: 164%;
				border-top: 1px solid #E2E2E2;
				border-bottom: 1px solid #E2E2E2;
				padding: 5px 0px;
				margin: -1px;
				&:hover {
				color: #F41611;
				cursor: pointer;
			}
			}
			&:hover {
				color: #F41611;
				cursor: pointer;
			}
		}
	}
}
}